/* global SC */
import React from 'react'
import Script from 'react-load-script'
import moment from 'moment'
import Img from 'gatsby-image'
import { SmallToMediumScreenOnly, LargeScreenUp } from '../../theme/'
import { Box, Heading, Text, Measure } from 'rebass'
import { FlexWrapper, Hr, Artwork, Player, Ctrl, Play, Pause, BuyLink, ArtistLink } from './styles'
import assetDefaultRecord from '../../images/artist-record.png'
import { unescape, lowerCase } from 'lodash'

class FeaturedRelease extends React.PureComponent {
  constructor (props) {
    super(props)
    this.soundcloud = null
    this.play = this.play.bind(this)
    this.state = {
      scriptLoaded: false,
      isSoundcloudReady: false,
      isSoundcloudPlaying: false
    }
  }

  componentDidMount () {
    this.setIframeRef = element => {
      this.iframe = element
      this.handleScriptLoad()
    }
  }

  handleScriptCreate () {
    this.setState({ scriptLoaded: false })
  }

  handleScriptError () {
    this.setState({ scriptError: true })
  }

  handleScriptLoad () {
    this.setState({ scriptLoaded: true })
    if (this.iframe) {
      this.soundcloud = SC.Widget(this.iframe)
      this.soundcloud.bind(SC.Widget.Events.READY, () => {
        this.setState({ isSoundcloudReady: true })
      })
    }
  }

  play () {
    if (this.props.soundcloudid) {
      this.setState({ isSoundcloudPlaying: !this.state.isSoundcloudPlaying })
      this.soundcloud.setVolume(40)
      this.soundcloud.toggle()
    }
  }

  render () {
    const artwork = this.props.artwork ? {
      alt: `${this.props.title} - ${this.props.artist}`,
      sizes: this.props.artwork.localFile.childImageSharp.sizes
    } : { }
    const specialMedia = {
      src: (this.props.specialmedia) ? this.props.specialmedia.localFile.childImageSharp.sizes.src : assetDefaultRecord
    }
    const isReleased = (moment(this.props.releasedate, 'DD/MM/YYYY').valueOf() <= moment().valueOf())
    const formattedDate = moment(this.props.releasedate, 'DD/MM/YYYY').format('DD.MM.YYYY')
    const preorderOrRelease = (isReleased) ? 'Buy ' : 'Pre-Order '
    const artistLink = (this.props.artist) ? <ArtistLink to={`/artists/${lowerCase(this.props.artist).replace(/ /g, '-')}/`}>{this.props.artist}</ArtistLink> : ''

    return (
      <FlexWrapper
        w='100%' height='100%' mx='auto' px={[25, null, 0]}
        alignItems='center' justifyContent={'center'}
        wrap
        invert={this.props.invert}
      >
        <Box w={[1 / 1, null, '29.71%']} mr={[0, null, 140]}>
          <LargeScreenUp>
            <Heading is='h2' f={5} mb={28} fontWeight='normal'>Featured Release</Heading>
          </LargeScreenUp>
          <Artwork
            record={specialMedia.src}
            mt={['5px', null, 0]} mb={['5vh', null, 0]} mx={[-25, null, 0]}
            onClick={this.play}
            isClickable={this.props.soundcloudid}
          >
            { this.props.artwork &&
              <Img
                src={artwork.sizes.src}
                sizes={artwork.sizes}
                alt={artwork.alt}
              />
            }
            <Ctrl left={0}>
              { !this.state.isSoundcloudPlaying && this.state.isSoundcloudReady && <Play /> }
              { this.state.isSoundcloudPlaying && this.state.isSoundcloudReady && <Pause /> }
            </Ctrl>
          </Artwork>
          <LargeScreenUp>
            <Box mt={43}>
              { this.props.buylink &&
                <BuyLink href={this.props.buylink} target='_blank' rel='noopener noreferrer'>
                  <Text fontSize={4} lineHeight={1.4}>{preorderOrRelease} on Bandcamp</Text>
                </BuyLink>
              }
              { this.props.releasedate &&
                <Text f={1}>Released { formattedDate }</Text>
              }
            </Box>
          </LargeScreenUp>

        </Box>
        <Box w={[1 / 1, null, '33%', '29%']} order={[2, null, 1]}>
          <SmallToMediumScreenOnly>
            <Heading is='h2' fontSize={['30px', null, 5]} mb={28} fontWeight='normal'>Featured Release</Heading>
          </SmallToMediumScreenOnly>
          {/* <a href={this.props.buylink} target='_blank' rel='noopener noreferrer'> */}
          { this.props.title &&
            <Heading is='h3' fontSize={[4, 5, 6, 7]} className='sans'>{ this.props.title }</Heading>
          }

          { this.props.artist &&
          <Text>by <em>{ artistLink }</em></Text>
          }
          {/* </a> */}
          <Hr mt={32} mb={[25, null, 32]} invert={this.props.invert} />
          <Measure pr={20} maxWidth={['100%', '75%', '100%']}>
            { this.props.description &&
              <Box f={1} dangerouslySetInnerHTML={{ __html: unescape(this.props.description) }} />
            }
            { this.props.media &&
              <Text is='p' f={1}>{this.props.media.map((media, key) => {
                let type = media.type
                let separator = (this.props.media.length - 1 !== key) ? ' / ' : ''
                return type + separator
              })}</Text>
            }
          </Measure>
          <SmallToMediumScreenOnly>
            <Box mt={20} mb={'8vh'}>
              { this.props.buylink &&
                <BuyLink href={this.props.buylink} target='_blank' rel='noopener noreferrer'>
                  <Text fontSize={4} lineHeight={1.4}>{preorderOrRelease} on Bandcamp</Text>
                </BuyLink>
              }
              { this.props.releasedate &&
                <Text f={1}>Released { formattedDate }</Text>
              }
            </Box>
          </SmallToMediumScreenOnly>
        </Box>
        { this.props.soundcloudid &&
          <Player>
            <iframe
              title='soundcloud player'
              ref={this.setIframeRef}
              allow='autoplay'
              src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/${this.props.soundcloudid}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false&visual=false`}
            />
            <Script
              url='https://w.soundcloud.com/player/api.js'
              onCreate={this.handleScriptCreate.bind(this)}
              onError={this.handleScriptError.bind(this)}
              onLoad={this.handleScriptLoad.bind(this)}
            />

          </Player>
        }

      </FlexWrapper>
    )
  }
}

export default FeaturedRelease
