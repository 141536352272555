import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { black } from '../theme'
import { isWindowAvailable } from '../util'
import { first } from 'lodash'
import Layout from '../components/Layout'
import FeaturedRelease from '../components/FeaturedRelease'
import Footer from '../components/Footer'
import Router from '../components/Router'
import PageTitle from '../components/PageTitle'

class HomePageTemplate extends Component {
  render () {
    const siteMetadata = this.props.data.site.siteMetadata
    const currentPage = this.props.data.wordpressPage
    const details = first(currentPage.acf.featured_release)
    // TODO: pass colour from wordpress
    const bgColor = (details.acf.featured_background) ? `${black.mid}` : '#F3F3F3'
    const bodyStyle = (isWindowAvailable) ? { style: `background-color: ${bgColor};` } : null
    return (
      <Layout invert={!details.acf.featured_background}>
        <Router
          items={first(this.props.data.allWordpressWpApiMenusMenusItems.edges).node.items}
          invert={!details.acf.featured_background}
        />
        { currentPage && currentPage.acf.seo_page_title &&
          <Helmet title={currentPage.acf.seo_page_title} bodyAttributes={bodyStyle} />
        }
        { currentPage && !currentPage.acf.seo_page_title &&
          <Helmet title={`${siteMetadata.title} - ${currentPage.title}`} bodyAttributes={bodyStyle} />
        }
        <PageTitle prefix invert={!details.acf.featured_background} />
        <FeaturedRelease
          title={details.post_title}
          artist={first(details.acf.release_artist).post_title}
          description={details.acf.release_description_short}
          releasedate={details.acf.release_date}
          buylink={details.acf.release_buy_link}
          media={details.acf.release_available_media}
          soundcloudid={details.acf.release_audio_preview}
          artwork={details.acf.release_artwork}
          specialmedia={details.acf.release_special_media}
          invert={!details.acf.featured_background}
        />
        {/* <ViewportBackground
          texture={assetTexture}
          colormode={details.acf.featured_background}
        /> */}
        <Footer
          home
          sticky
          invert={!details.acf.featured_background}
        />
      </Layout>
    )
  }
}
export default HomePageTemplate

export const homePageQuery = graphql`
  query HomePageQuery($id: String!) {
    allWordpressWpApiMenusMenusItems(
      filter: { name: { regex: "/Router/" } },
      sort: { fields: [name], order: DESC}
    ) {
      edges {
        node {
          id
          name
          items {
            title
            url
            type
          }
        }
      }
    }
    wordpressPage(id: { eq: $id }) {
      title
      date(formatString: "MMMM DD, YYYY")
      acf {
        seo_page_title
        featured_background
        featured_release {
          post_title
          acf {
            release_artist {
              post_title
            }
            release_date
            release_id
            release_buy_link
            release_description_short
            release_available_media {
              type
            }
            release_audio_preview
            release_artwork {
              localFile {
                childImageSharp {
                  sizes(
                    maxWidth: 1000,
                    maxHeight: 1000
                  ) {
                    ...GatsbyImageSharpSizes_withWebp
                  }
                }
              }
              source_url
            }
            release_special_media {
              localFile {
                childImageSharp {
                  
                  sizes(
                    maxWidth: 1000,
                    maxHeight: 1000
                  ) {
                    ...GatsbyImageSharpSizes
                  }
                }
              }
            }
          }
        }
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`
