import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'
import { media, easeOutExpo, ctaButtonColor } from '../../theme/'
import { Flex, Box, Link, Absolute } from 'rebass'
import BtnPause from '../../vectors/BtnPause'
import BtnPlay from '../../vectors/BtnPlay'

export const Play = styled(BtnPlay)`
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 1;
`
export const Pause = styled(BtnPause)`
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 1;
`

export const Ctrl = styled(Absolute)`
  transform: translateX(-50%);
  z-index: 1;
`

export const BuyLink = styled(Link)`
  display: inline-block;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #BB1816;
    transform: translateY(300%);
    opacity: 0;
    transition: transform 0.8s ${easeOutExpo}, opacity 0.8s ${easeOutExpo};
  }
  &:hover {
    &:after {
      transform: translateY(50%);
      opacity: 1;
    }
  }
`

export const FlexWrapper = styled(Flex)`
  color: ${props => (props.invert) ? 'black' : 'white'};
  position: relative;
  top: 0;
  z-index: 1;
  ${media.LargeScreenUp`
    position: fixed;
    height: 94vh;
    min-height: 571px;
  `};
  a {
    color: ${props => (props.invert) ? '#BB1816' : 'white'};
    text-decoration: none;
  }
  h3 {
    color: ${props => (props.invert) ? '#BB1816' : 'white'};
  }
`
export const Hr = styled(Box)`
  border-top: 1px white solid;
  max-width: 50%;
  ${media.LargeScreenUp`
    max-width: 100%;
    border-color: ${props => (props.invert) ? 'black' : 'white'};
  `};
`
export const Player = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(-100%, -100%);
`
export const Artwork = styled(Box)`
    position: relative;
    ${props => props.isClickable ? 'cursor: pointer;' : ''}
    &:hover {
      ${media.LargeScreenUp`
      transform: translateX(-3%);
      `}
    }
    transition: transform 0.5s ${easeOutExpo};
    img {
      position: relative;
      z-index: 1;
      margin: 0 auto;
      ${media.LargeScreenUp`
        margin: 0;
      `};
      background-color: black;
    }
    &:after {
      content:'';
      position: absolute;
      z-index: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%) scale(0.99);
      ${media.LargeScreenUp`
        left: 2%;
        transform: translateX(0) scale(0.99);
      `};
      width: 90%;
      max-width: 400px;
      margin: 0 auto;
      height: 90%;
      box-shadow: 10px 0 65px 1px rgba(51, 51, 51, 0.6),
      10px 0 45px 1px rgba(0,0,0, 0.4);
      transition: transform 0.5s ${easeOutExpo};
    }
    &:before {
    ${media.LargeScreenUp`
      content:'';
      position: absolute;
      z-index: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-35%);
      width: 99%;
      height: 99%;
      background-image: url(${props => props.record});
      background-size: contain;
      background-repeat: no-repeat;
      transition: transform 0.5s ${easeOutExpo};
    `};
    }
    &:hover::before {
      ${media.LargeScreenUp`
        transform: translateX(-25%) rotate(12deg);
      `};
    }
`
export const ArtistLink = styled(GatsbyLink)`
  color: rgba(0, 0, 0, 0.75) !important;
  &:hover {
    color: ${ctaButtonColor} !important;
  }
`
